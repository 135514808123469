import React, {createContext, useContext, useEffect, useState} from 'react';
import {COMPANY_TYPES, CONSENTS, CONSENT_VERSIONS} from 'configs';
import {
  get,
  isEmpty,
  isObject,
  keys,
  merge,
  pick,
  omit,
  has,
  every,
} from 'lodash';
import Cookies from 'utils/cookies';
import {login, logout, getAuthSession, loginOAuth, post2FA} from 'api/auth';
import cleanDeep from 'clean-deep';

import {useConsent} from './ConsentContext';
import DataLayerPush from 'utils/gtm';
import {COMPANY_BUSINESS_TYPE} from 'configs/types';

export const AuthContext = createContext({});
export const AuthConsumer = AuthContext.Consumer;

const MAIN_ACCOUNTS = omit(COMPANY_TYPES, ['ab', 'cb', 'mncb']);
const DC_ACCOUNTS = omit(COMPANY_TYPES, ['company', 'ce']);

const ACCOUNT_DATA = [
  'account_id',
  'company_id',
  'account_email',
  'account_first_name',
  'account_last_name',
  'account_role',
  'account_status',
  'account_image',
  'account_confirm_email',
  'account_confirm_mobile',
  'account_occupations_name',
  'entity_id',
  'company.company_id',
  'company.company_name',
  'company.company_type',
  'company.template_version',
  'company.is_default',
  'company.template_version',
  'company.signed_cb_data_management_agreement',
  'company.subscription_types',
  'company.subscription',
  'company.company_business_type',
];

export function validateConsents(companyType, consents = {}, isDefault) {
  if (isEmpty(consents) || !isObject(consents)) return null;

  let consentsByCompany;

  const isAB = companyType === COMPANY_TYPES.ab;
  const isCB = companyType === COMPANY_TYPES.cb;
  const isCE = companyType === COMPANY_TYPES.ce;

  if (isAB) {
    consentsByCompany = pick(CONSENT_VERSIONS, [
      CONSENTS.terms,
      CONSENTS.privacy,
    ]);
  } else if (isCB) {
    consentsByCompany = pick(CONSENT_VERSIONS, [
      CONSENTS.terms,
      CONSENTS.privacy,
      CONSENTS.data,
    ]);

    if (!isDefault) {
      consentsByCompany = pick(CONSENT_VERSIONS, [
        CONSENTS.terms,
        CONSENTS.privacy,
      ]);
    }
  } else if (isCE) {
    consentsByCompany = pick(CONSENT_VERSIONS, [
      CONSENTS.terms,
      CONSENTS.privacy,
      CONSENTS.qt,
    ]);
  } else {
    consentsByCompany = pick(CONSENT_VERSIONS, [
      CONSENTS.terms,
      CONSENTS.privacy,
    ]);
  }

  return keys(consentsByCompany).every((item) => {
    let validConsent, validStatus, validVersion;

    // QT Consent is optional.
    if (item === CONSENTS.qt) {
      validConsent = consents.hasOwnProperty(item);
      validStatus = true;

      // Check version, when status is 1 only.
      if (validConsent && consents[item].status === 1) {
        validVersion =
          validConsent &&
          parseFloat(consents[item].version) ===
            parseFloat(CONSENT_VERSIONS[item]);
      } else {
        validVersion = true;
      }
    } else {
      validConsent = consents.hasOwnProperty(item);
      validStatus = validConsent && consents[item].status === 1;
      validVersion =
        validConsent &&
        parseFloat(consents[item].version) ===
          parseFloat(CONSENT_VERSIONS[item]);
    }

    return validConsent && validStatus && validVersion;
  });
}

export function validateConsent(consents = {}) {
  if (isEmpty(consents) || !isObject(consents)) return null;
  return consents;
}

function validateToken(token = '') {
  if (isEmpty(token)) return null;
  return token;
}

function validateAccount(account = {}) {
  if (isEmpty(account) || !isObject(account)) return null;
  return pick(account, ACCOUNT_DATA);
}

function parseSession(token, account, consent, permission) {
  return cleanDeep({
    token: validateToken(token),
    account: validateAccount(account),
    consent: validateConsent(consent),
    permission: permission, // Only AB use permission. should skip for others.
  });
}

export function AuthProvider(props) {
  const {children} = props;
  const {consentBanner} = useConsent();
  const {eventAuth} = DataLayerPush;
  const [auth, setAuth] = useState(generateAuth());
  const [isFetchingSession, setIsFetchingSession] = useState(false);

  const {isAuth} = auth || {};

  useEffect(() => {
    const {token} = auth;
    if (token) {
      void handleLoginBySession(token);
    }
  }, []);

  useEffect(() => {
    // if the consent is accepted then we are going to fire the event auth checker
    if (!consentBanner) eventAuth();
  }, [isAuth, consentBanner]);

  function generateAuth() {
    const session = getSession();

    const {token, account, consent, permission, isAuth} = session || {};
    const companyType = get(account, 'company.company_type', '');
    const companyBusinessType = get(account, 'company.company_business_type');

    // const isAuth = !isEmpty(session);
    const isDefault = get(account, 'company.is_default', 0) === 1;
    const isConsented =
      isAuth && !!validateConsents(companyType, consent, isDefault);
    const hasPermission = !isEmpty(permission);

    return {
      isAuth: isAuth,
      isConsented: isConsented,
      isDefault: isDefault,
      hasPermission: hasPermission,
      token: token,
      account: account,
      consent: consent,
      permission: permission,
      companyType: companyType,
      companyBusinessType,
    };
  }

  function getSession() {
    const session = Cookies.session || {};

    const {token, account, consent, permission} = session;
    const companyType = get(account, 'company.companyType', '');

    const isAuth = !isEmpty(session);
    const isDefault = get(account, 'company.is_default', 0) === 1;
    const isConsented =
      isAuth && !!validateConsents(companyType, consent, isDefault);
    const hasPermission = !isEmpty(permission);

    return {
      isAuth: isAuth,
      isConsented: isConsented,
      isDefault: isDefault,
      hasPermission: hasPermission,
      token: token,
      account: account,
      consent: consent,
      permission: permission,
      companyType: companyType,
    };
  }

  function setSession(data) {
    let session = '';

    if (!isEmpty(data) && isObject(data)) {
      const {token, account, consent, permission} = data;
      session = parseSession(token, account, consent, permission);
    }

    // Replace Browser Cookie
    Cookies.session = session;

    // Replace Auth states
    const auth = generateAuth();
    setAuth({...auth});
  }

  function updateSession(data) {
    let session = '';
    if (!isEmpty(data) && isObject(data)) {
      const {token, account, consent, permission} = data;

      const newSession = parseSession(token, account, consent, permission);

      const oldSession = getSession();
      session = merge({}, oldSession, newSession);
    }

    // Replace Browser Cookie
    Cookies.session = session;

    const auth = generateAuth();
    setAuth(auth);
  }

  async function handleLogin(
    email,
    password,
    remember = false,
    secret,
    eg_code
  ) {
    try {
      const {data} = await login({email, password, remember, secret, eg_code});

      const {session_id, account, consent, permission} = data;

      setSession({
        token: session_id,
        account: account,
        consent: consent,
        permission: permission,
      });

      return Promise.resolve(data);
    } catch (e) {
      throw e;
    }
  }

  async function handle2FA({token, secret, eg_code}) {
    try {
      const {data} = await post2FA({token, secret, eg_code});
      const {session_id, account, consent, permission} = data;

      setSession({
        token: session_id,
        account: account,
        consent: consent,
        permission: permission,
      });

      return Promise.resolve(data);
    } catch (e) {
      throw e;
    }
  }

  async function handleLoginBySession(sessionId) {
    setIsFetchingSession(true);
    try {
      const {data} = await getAuthSession(sessionId);
      const {account, consent, permission} = data;

      setSession({
        token: sessionId,
        account: account,
        consent: consent,
        permission: permission,
      });
      return Promise.resolve(data);
    } catch (e) {
      throw e;
    } finally {
      setIsFetchingSession(false);
    }
  }

  async function handleOAuth({
    email,
    password,
    remember = false,
    secret,
    app_id,
  }) {
    try {
      const {data} = await loginOAuth({
        email,
        password,
        remember,
        secret,
        app_id,
      });
      const {session_id, account, consent, permission} = data;

      setSession({
        token: session_id,
        account: account,
        consent: consent,
        permission: permission,
      });

      return Promise.resolve(data);
    } catch (e) {
      throw e;
    }
  }

  async function handleLogout() {
    try {
      await logout();
      setSession(null);

      await new Promise((resolve) => setTimeout(resolve, 500)); // Freeze
      return Promise.resolve(true);
    } catch (e) {
      throw e;
    }
  }

  const {
    isConsented,
    isDefault,
    hasPermission,
    token,
    account,
    companyType,
    consent,
    permission,
    companyBusinessType,
  } = auth;

  const isDCAccount = has(DC_ACCOUNTS, companyType);
  const isMainAccount = has(MAIN_ACCOUNTS, companyType);

  const isCompany = companyType === COMPANY_TYPES.company;
  const isRegulator = every([
    isCompany,
    companyBusinessType === COMPANY_BUSINESS_TYPE.regulator,
  ]);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        isConsented,
        isDefault,
        hasPermission,
        token,
        account,
        companyType,
        isDCAccount,
        isMainAccount,
        consent,
        permission,
        isCompany,
        isRegulator,
        handleLogin,
        handle2FA,
        handleOAuth,
        handleLoginBySession,
        handleLogout,
        getSession,
        setSession,
        updateSession,
        isFetchingSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function withAuth(Component) {
  return function (props) {
    const auth = useContext(AuthContext);

    return <Component auth={auth} {...props} />;
  };
}

export const useAuth = () => useContext(AuthContext);
