import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {getSearchSuggestCertificate} from 'api/search';
import {keys, first, drop, slice} from 'lodash';
import {Row, Col, Button} from 'reactstrap';
import Cookies from 'utils/cookies';
import {alertByError} from 'utils/alerts';
import {useAuth} from 'contexts/AuthContext';
import {removePrefix} from 'utils/string';
import {MDB_PREFIX, VERIFIED_IMAGE} from 'configs';
import urlJoin from 'url-join';
import {IAF_MAIN_URL} from 'configs/Environment';
import {components} from 'react-select';
import classNames from 'classnames';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import styles from './styles.module.scss';
import {COMPANY_TYPE} from 'configs/types';
import {debounce} from 'lodash';
import SearchSelect from './SearchSelect';

const SearchSelectCertOption = (props) => {
  const {data, ...rest} = props;
  const {cert_number, ces = []} = data || {};

  if (!ces) {
    return <components.Option {...rest}>{cert_number}</components.Option>;
  }

  const firstCE = first(ces) || '';
  const restCEs = slice(ces, 1);

  return (
    <components.Option {...rest}>
      <p
        className={classNames('text-dark font-weight-bold p-0', ces && 'mb-1')}
        style={{fontSize: '12px'}}
      >
        {cert_number}
      </p>

      {ces && (
        <div>
          {firstCE && (
            <p
              className="text-secondary p-0 m-0"
              style={{fontSize: '12px'}}
              title={firstCE}
            >
              <span style={{overflowWrap: 'break-word'}}>{firstCE}</span>
              {restCEs.length > 0 && (
                <span
                  style={{color: 'var(--gray)', filter: 'opacity(0.5)'}}
                  className="ml-1"
                >
                  (+{restCEs.length} more)
                </span>
              )}
            </p>
          )}
        </div>
      )}
    </components.Option>
  );
};

const SearchSelectCertNoMessage = (props) => {
  const {selectProps, isAuth, isVuAccount, limit} = props;
  const {inputValue} = selectProps;
  let message;

  if (inputValue) {
    if (ENABLE_SUBSCRIPTION && isVuAccount) {
      message = <span className="text-muted">No recent searches</span>;
    } else {
      message = (
        <>
          <div className="mb-2">
            Can’t find the certification, you’re looking for?
          </div>
          <div>
            <Link to="/enquiry">Information request form</Link>
          </div>
        </>
      );
    }
  } else {
    if (isAuth) {
      if (ENABLE_SUBSCRIPTION && isVuAccount) {
        message = <span className="text-muted">No recent searches</span>;
      } else {
        const {try: tryLimit, max: maxLimit} = limit;
        message = (
          <Row>
            <Col lg={1}>
              <i
                className="fa fa-exclamation-circle fa-4x"
                style={{color: 'orange'}}
              />
            </Col>
            <Col>
              <div>
                <b>
                  {tryLimit} / {maxLimit} search limit used.
                </b>
                <br />
                Want to increase your search limit? Contact us at,
                <br />
                <a href="mailto: support@iafcertsearch.org">
                  <b>IAF CertSearch support.</b>
                </a>
              </div>
            </Col>
          </Row>
        );
      }
    } else {
      message = (
        <div>
          <span>Please provide certificate number to start searching</span>
          <hr />
          <Row className="d-flex flex-row justify-content-center align-items-center text-left mx-0 my-2">
            <Col xs="auto">
              <img src={VERIFIED_IMAGE} alt="try" />
            </Col>

            <Col>
              <h4 className="mb-1">Start your 14-day free trial</h4>
              <span className="mb-1">
                Enjoy enhanced capabilities during your free trial, including:
              </span>
              <ul className="mb-1">
                <li>
                  <strong>Bulk Verification</strong>: Verify multiple
                  certifications simultaneously.
                </li>
                <li>
                  <strong>Watchlist Management</strong>: Monitor key
                  certifications automatically.
                </li>
                <li>
                  <strong>Real-Time Alerts</strong>: Receive instant
                  notifications on status updates.
                </li>
              </ul>
              <span className="mb-1">
                You may cancel anytime before the trial ends to avoid charges.
              </span>
            </Col>
            <Col xs={12} md="auto" className="text-center mt-2 mt-lg-0">
              <Button
                tag={Link}
                to="/register/user?subscription=free_trial"
                color="outline-primary"
                className="mx-3"
              >
                Start Free Trial
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
  }

  return <div className={styles['search__not-found']}>{message}</div>;
};

function SearchSelectCert(props) {
  const {onInputChange, onSelect, verifyCaptcha, ...rest} = props;
  const {isAuth, companyType, isMainAccount, isCompany} = useAuth();
  const isCE = companyType === COMPANY_TYPE.ce;

  const [keyword, setKeyword] = useState('');
  const history = useHistory();

  async function loadOptions(value, callback) {
    setKeyword(value);

    let result = [];

    if (value) {
      try {
        const {data} = await getSearchSuggestCertificate({query: value});

        if (Array.isArray(data)) {
          result = data.map((item) => {
            let obj = {};
            for (const [key, value] of Object.entries(item)) {
              obj = {
                company_certifications_id: key,
                cert_number: value,
              };
            }
            return obj;
          });
        } else {
          result = keys(data).map((item) => {
            const cert_number = data[item].length > 0 ? first(data[item]) : '';
            const ces = data[item].length > 1 ? drop(data[item], 1) : [];

            return {
              company_certifications_id: item,
              cert_number: cert_number,
              ces: ces,
            };
          });
        }
      } catch (e) {
        if (e.status === 429) {
          Cookies.captchaToken = '';
          await alertByError(e);
        } else {
          if (e.code === 'invalid_captcha_token') verifyCaptcha();
        }
      }
    }

    if (onInputChange) onInputChange(value, result);
    return callback(result);
  }

  function handleChange(selected) {
    if (selected) {
      const {company_certifications_id} = selected;
      const mdbCertId = removePrefix(
        company_certifications_id,
        MDB_PREFIX.cert
      );

      if (!isMainAccount) {
        window.location.replace(
          urlJoin(IAF_MAIN_URL, '/certification', mdbCertId)
        );
      } else {
        history.push({
          pathname: `/certification/${mdbCertId}`,
          state: {
            keyword: keyword,
          },
        });
      }

      if (onSelect) onSelect(selected, keyword);
    }
  }

  const additionalComponents = (limit) => {
    return {
      Option: (props) => <SearchSelectCertOption {...props} />,
      NoOptionsMessage: (props) => (
        <SearchSelectCertNoMessage
          {...props}
          limit={limit}
          isAuth={isAuth}
          isVuAccount={isCompany || isCE}
        />
      ),
    };
  };

  const searchSelectProps = {
    onChange: handleChange,
    getOptionLabel: (option) => option.cert_number,
    getOptionValue: (option) => option.company_certifications_id,
    loadOptions: debounce(loadOptions, 500),
    placeholder: 'Search certification by certificate number',
    ...rest,
  };

  return (
    <SearchSelect
      type="certification"
      keyword={keyword}
      additionalComponents={additionalComponents}
      {...searchSelectProps}
    />
  );
}

export default SearchSelectCert;
