import React from 'react';

import {Nav} from 'reactstrap';

import PrivateNavPlan from './PrivateNavPlan';
import PrivateNavUser from './PrivateNavUser';
import PrivateNavLimit from './PrivateNavLimit';
import PrivateNavSearch from './PrivateNavSearch';
import PrivateNavNotification from './PrivateNavNotification';

import {useAuth} from 'contexts/AuthContext';
import {useLimits} from 'contexts/LimitContext';
import {useSubscription} from 'contexts/SubscriptionContext';
import {usePage} from 'contexts/PageContext';

import {debounce, get} from 'lodash';
import {IconFA} from 'components/Icons';
import styles from './styles.module.scss';

import {ACCOUNT_ROLES, COMPANY_TYPES} from 'configs';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import {SUBSCRIPTION_PLANS_TYPE} from 'configs/subscription';

function PrivateNav(props) {
  const {dark, isMobile} = props;
  const {setSidebar, isOpenLeftSidebar, sidebar} = usePage();

  const auth = useAuth();
  const {limit} = useLimits();
  const {isSubscribed, subscriptionPlan} = useSubscription();
  const isSuperAdmin =
    get(auth, 'account.account_role') === ACCOUNT_ROLES.super_admin;

  const isCompany = get(auth, 'isCompany');
  const isStandard = subscriptionPlan === SUBSCRIPTION_PLANS_TYPE.standard;
  const accountType = get(auth, 'companyType');
  const isCE = accountType === COMPANY_TYPES.ce;

  const isVU = isCE || isCompany;

  const toggleSidebar = debounce(() => {
    setSidebar('left', !isOpenLeftSidebar, true);
  }, 100);

  return (
    <>
      {!!sidebar && (
        <span
          onClick={toggleSidebar}
          className="d-xl-none p-1 ml-2 text-primary cursor-pointer"
        >
          <IconFA name="bars" />
        </span>
      )}
      <div className={styles['private__nav__search__container']}>
        <PrivateNavSearch isMobile={isMobile} hasSidebar={!!sidebar} />
      </div>

      <Nav className="align-items-center ml-auto flex-row" navbar>
        {ENABLE_SUBSCRIPTION && isVU && (
          <>
            {limit >= 0 && isSubscribed && <PrivateNavLimit />}
            {isSuperAdmin && !isStandard && <PrivateNavPlan />}
          </>
        )}

        <PrivateNavNotification dark={dark} />

        <PrivateNavUser />
      </Nav>
    </>
  );
}

export default PrivateNav;
