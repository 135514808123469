import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {getSearchSuggestCompany} from 'api/search';
import {alertByError} from 'utils/alerts';
import Cookies from 'utils/cookies';
import {useAuth} from 'contexts/AuthContext';
import {IAF_MAIN_URL} from 'configs/Environment';
import urljoin from 'url-join';
import {removePrefix} from 'utils/string';
import {MDB_PREFIX} from 'configs';
import {Link} from 'react-router-dom';
import {Row, Col, Button} from 'reactstrap';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import {VERIFIED_IMAGE} from 'configs';
import styles from './styles.module.scss';
import {COMPANY_TYPE} from 'configs/types';
import SearchSelect from './SearchSelect';
import debounce from 'lodash/debounce';
import {components} from 'react-select';
import {ImageFlag} from 'components/Images';
import {getCountryCodeById} from 'utils/locales';

function SearchSelectCENoMessage(props) {
  const {selectProps, isAuth, isVuAccount, limit} = props;
  const {inputValue} = selectProps;

  let message;

  if (inputValue) {
    if (ENABLE_SUBSCRIPTION && isVuAccount) {
      message = <span className="text-muted">No recent searches</span>;
    } else {
      message = (
        <>
          <div className="mb-2">
            Can’t find the company, you’re looking for?
          </div>
          <div>
            <Link to="/enquiry">Information request form</Link>
          </div>
        </>
      );
    }
  } else {
    if (isAuth) {
      if (ENABLE_SUBSCRIPTION && isVuAccount) {
        message = <span className="text-muted">No recent searches</span>;
      } else {
        const {try: tryLimit, max: maxLimit} = limit;
        message = (
          <Row>
            <Col lg={1}>
              <i
                className="fa fa-exclamation-circle fa-4x"
                style={{color: 'orange'}}
              />
            </Col>
            <Col>
              <div>
                <b>
                  {tryLimit} / {maxLimit} search limit used.
                </b>
                <br />
                Want to increase your search limit? Contact us at,
                <br />
                <a href="mailto: support@iafcertsearch.org">
                  <b>IAF CertSearch support.</b>
                </a>
              </div>
            </Col>
          </Row>
        );
      }
    } else {
      if (ENABLE_SUBSCRIPTION) {
        message = (
          <div>
            <span>Please provide company name to start searching</span>
            <hr />
            <Row className="d-flex flex-row justify-content-center align-items-center text-left mx-0 my-2">
              <Col xs="auto">
                <img src={VERIFIED_IMAGE} alt="try" />
              </Col>

              <Col>
                <h4 className="mb-1">Start your 14-day free trial</h4>
                <span className="mb-1">
                  Enjoy enhanced capabilities during your free trial, including:
                </span>
                <ul className="mb-1">
                  <li>
                    <strong>Bulk Verification</strong>: Verify multiple
                    certifications simultaneously.
                  </li>
                  <li>
                    <strong>Watchlist Management</strong>: Monitor key
                    certifications automatically.
                  </li>
                  <li>
                    <strong>Real-Time Alerts</strong>: Receive instant
                    notifications on status updates.
                  </li>
                </ul>
                <span className="mb-1">
                  You may cancel anytime before the trial ends to avoid charges.
                </span>
              </Col>
              <Col xs={12} md="auto" className="text-center mt-2 mt-lg-0">
                <Button
                  tag={Link}
                  to="/register/user?subscription=free_trial"
                  color="outline-primary"
                  className="mx-3"
                >
                  Start Free Trial
                </Button>
              </Col>
            </Row>
          </div>
        );
      } else {
        message = 'Provide company name to search...';
      }
    }
  }

  return <div className={styles['search__not-found']}>{message}</div>;
}

function FlagOption(props) {
  const {data} = props;
  const {company_name, countries_id} = data || {};

  const countryCode = getCountryCodeById(countries_id) || null;

  return (
    <components.Option {...props}>
      <div className="d-flex align-items-center flex-row">
        {!!countries_id && !!countryCode ? (
          <ImageFlag code={countryCode} className="mr-2" />
        ) : (
          <div className="mx-3" />
        )}
        <span>{company_name}</span>
      </div>
    </components.Option>
  );
}

function SearchSelectCE(props) {
  const {onInputChange, onSelect, verifyCaptcha, ...rest} = props;

  const history = useHistory();
  const [keyword, setKeyword] = useState('');

  const {isAuth, isMainAccount, companyType, isCompany} = useAuth();
  const isCE = companyType === COMPANY_TYPE.ce;

  async function loadOptions(value, callback) {
    setKeyword(value);

    let result = [];

    try {
      const {data} = await getSearchSuggestCompany({query: value});

      result = data.map((item) => {
        let obj = {};
        for (const [key, value] of Object.entries(item)) {
          obj = {company_id: key, company_name: value.company_name, ...value};
        }

        return obj;
      });
    } catch (e) {
      if (e.status === 429) {
        Cookies.captchaToken = '';
        await alertByError(e);
      } else {
        if (e.code === 'invalid_captcha_token') verifyCaptcha();
      }
    }

    if (onInputChange) onInputChange(value, result);
    return callback(result);
  }

  async function handleChange(selected) {
    if (selected) {
      const {company_id, company_name, countries_id} = selected;
      const mdbCeId = removePrefix(company_id, MDB_PREFIX.ce);

      if (!isMainAccount) {
        window.location.replace(
          urljoin(IAF_MAIN_URL, `/certified-entity/${mdbCeId}`)
        );
      } else {
        history.push({
          pathname: `/certified-entity/${mdbCeId}`,
          state: {
            keyword: keyword,
            company_name: company_name,
            countries_id: countries_id,
          },
        });
      }

      if (onSelect) onSelect(selected, keyword);
    }
  }

  const additionalComponents = (limit) => {
    return {
      NoOptionsMessage: (props) => (
        <SearchSelectCENoMessage
          {...props}
          limit={limit}
          isAuth={isAuth}
          isVuAccount={isCompany || isCE}
        />
      ),
      Option: FlagOption,
    };
  };

  const searchSelectProps = {
    onChange: handleChange,
    getOptionLabel: (option) => option.company_name,
    getOptionValue: (option) => option.company_id,
    loadOptions: debounce(loadOptions, 500),
    placeholder: 'Search certification by company name',
    ...rest,
  };

  return (
    <SearchSelect
      type="company"
      keyword={keyword}
      additionalComponents={additionalComponents}
      {...searchSelectProps}
    />
  );
}

export default SearchSelectCE;
